import { CopyClipboard, BadgeSkin, Notification, Modal, CircularProgressBar, ToggleSwitch, FormField, SectionHelper, InputArea, Input, CustomModalLayout, TableActionCell, AnnouncementModalLayout, Badge, Card, PageSection, MarketingLayout, Table, Loader, Text, TableToolbar, Image, EmptyState, Page, Button, TextButton, Layout, Cell, Box, Tooltip, IconButton, WixDesignSystemProvider } from '@wix/design-system'
import '@wix/design-system/styles.global.css';
import { DuplicateSmall, PremiumFilled, HelpCircle, Flag, ExternalLinkSmall, Add, Link, ExternalLink } from '@wix/wix-ui-icons-common'
import React from 'react';
import { To, useNavigate, useLocation } from "react-router-dom";
import { requestMap, responseMap } from './utils/map.js'
import axios from 'axios';
import { ReactTagManager } from 'react-gtm-ts';

const tagManagerArgs = {
    code: 'GTM-WZQPMC7'
}

function Home() {
    const baseURL = "https://certifiedcode.editorx.io/ecpay-shipments/_functions";
    const [showTrialEndedSection, setShowTrialEndedSection] = React.useState(false);

    function convertObjToList(obj: { obj: Object }, map: { map: Object }) {
        return Object.keys(obj).map((key) => { return `${(map as any)[key] || key}: ${(obj as any)[key] || "(無)"}` }).join("\n")
    }

    ReactTagManager.init(tagManagerArgs)
    let navigate = useNavigate();
    const routeChange = (path: To) => {
        navigate(path);
    }

    const [orders, setOrders] = React.useState([
        {
            orderId: "",
            number: 0,
            ecpayInvoiceNumber: "-",
            ecpayStatusMsg: "-",
            status: <Badge skin='neutral'>-</Badge>
        }
    ]);
    // const currentTransationData = shipments[0]
    const [dashboard, setDashboard] = React.useState(null as any);
    const [merchantId, setMerchantId] = React.useState("");
    const [hashKey, setHashKey] = React.useState("");
    const [hashIV, setHashIV] = React.useState("");
    const [senderAddress, setSenderAddress] = React.useState("");
    const [senderCellPhone, setSenderCellPhone] = React.useState("");
    const [senderName, setSenderName] = React.useState("");
    const [senderPhone, setSenderPhone] = React.useState("");
    const [senderZipCode, setSenderZipCode] = React.useState("");
    const [isCreateOnTransaction, setIsCreateOnTransaction] = React.useState(false);
    const [showErrorBanner, setShowErrorBanner] = React.useState(false);
    const [errorText, setErrorText] = React.useState("未知的錯誤");
    const [successText, setSuccessText] = React.useState("成功");
    const [showSuccessBanner, setShowSuccessBanner] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [shownUpgradeModal, setShownUpgradeModal] = React.useState(false);
    const [shownTransactionModal, setShownTransactionModal] = React.useState(false);
    const [shownSettingsModal, setShownSettingsModal] = React.useState(false);
    const [currentTransaction, setCurrentTransaction] = React.useState("");
    const [currentUrl, setCurrentUrl] = React.useState("");
    const [showCopyUrlModal, setShowCopyUrlModal] = React.useState(false);
    React.useEffect(() => {
        loadDashboard()
    }, []);

    function loadDashboard() {
        axios.get(`${baseURL}/dashboard`, {
            headers: {
                Authorization: new URLSearchParams(window.location.search).get('instance')
            }
        }).then((response) => {
            setDashboard(response.data);
            setHashKey(response.data?.configuration?.hashKey);
            setHashIV(response.data?.configuration?.hashIV);
            setMerchantId(response.data?.configuration?.merchantId);
            setSenderAddress(response.data?.configuration?.senderAddress);
            setSenderCellPhone(response.data?.configuration?.senderCellPhone);
            setSenderName(response.data?.configuration?.senderName);
            setSenderPhone(response.data?.configuration?.senderPhone);
            setSenderZipCode(response.data?.configuration?.senderZipCode);
            if (!response.data?.configuration?.hashKey || !response.data?.configuration?.hashIV || !response.data?.configuration?.merchantId || !response.data?.configuration?.senderAddress || !response.data?.configuration?.senderCellPhone || !response.data?.configuration?.senderName || !response.data?.configuration?.senderPhone || !response.data?.configuration?.senderZipCode) {
                if (window.location.search.includes('fromSetup')) {
                    setShownSettingsModal(true);
                } else {
                    routeChange(`/getting-started?instance=${new URLSearchParams(window.location.search).get('instance')}`)
                }
            }
            setIsCreateOnTransaction(response.data?.configuration?.isCreateOnTransaction);
            setOrders((response.data as any)['orders'].map((v: { number: number; id: string }) => {
                const invoice = (response.data as any)['shipments'].find((inv: any) => inv.orderId === v.id && inv.ecpayResponse) || (response.data as any)['shipments'].find((inv: { orderId: string; }) => inv.orderId === v.id)
                var skin: BadgeSkin = 'neutral'
                var text = '待建立'
                var tooltipText = '要建立運單，請到右側動作欄點擊「建立物流選擇連結」'
                if (invoice?.ecpayResponse?.RtnCode === 1 || invoice?.ecpayResponse?.AllPayLogisticsID) {
                    skin = 'success'
                    text = '成功'
                    tooltipText = '要管理已建立的運單，請前往綠界管理介面進行'
                } else {
                    if (invoice?.ecpayError) {
                        skin = 'danger'
                        text = '發生錯誤'
                        tooltipText = invoice?.ecpayError
                    } else {
                        if (invoice?.link) {
                            skin = 'standard'
                            text = '待選擇'
                            tooltipText = '要選擇物流方式，請到右側動作欄點擊「複製物流選擇連結」並分享給客戶'
                        }
                    }
                }
                return {
                    orderId: v.id,
                    number: v.number || 0,
                    ecpayInvoiceNumber: invoice?.ecpayResponse?.LogisticsID || invoice?.ecpayResponse?.AllPayLogisticsID || "",
                    ecpayStatusMsg: invoice?.ecpayResponse?.RtnMsg || "",
                    status: <Tooltip placement="left" appendTo="window" content={tooltipText} size="small">
                        <Badge size="small" skin={skin}>{text}</Badge>
                    </Tooltip>
                }
            }))
            setIsLoaded(true);
        }).catch((error) => {
            console.error(error)
            setIsLoaded(true);
        })
    }

    function createshipmentlink(orderId: string) {
        axios
            .get(`${baseURL}/createshipmentlink/${orderId}`, {
                params: {
                    instance: new URLSearchParams(window.location.search).get('instance')
                }
            })
            .then((response: any) => {
                loadDashboard();
                console.log(response);
                if (!response['data']) {
                    setErrorText("請稍候再試");
                    setShowErrorBanner(true);
                    setTimeout(() => {
                        setShowErrorBanner(false)
                    }, 3000)
                }
                if (response['data']['success'] === true) {
                    setSuccessText("成功建立運單");
                    setShowSuccessBanner(true);
                    setCurrentUrl(`https://api.certifiedco.de/link/` + response['data']['link']['_id'])
                    setShowCopyUrlModal(true)
                    setTimeout(() => {
                        setShowSuccessBanner(false)
                    }, 3000)
                } else {
                    if (response['data']['reason']) {
                        setErrorText(response['data']['reason'])
                    }
                    setShowErrorBanner(true);
                    setTimeout(() => {
                        setShowErrorBanner(false)
                    }, 3000)
                }
            });
    }

    function getUpgradeUrl(isLifetime: boolean) {
        axios
            .get(`${baseURL}/upgradeUrl`, {
                params: {
                    instance: new URLSearchParams(window.location.search).get('instance'),
                    isLifetime
                }
            })
            .then((response: any) => {
                window.open(response['data'], '_blank')
            });
    }

    function updateSettings() {
        axios({
            method: 'post',
            url: `${baseURL}/updatesettings?instance=${new URLSearchParams(window.location.search).get('instance')}`,
            data: JSON.stringify({
                merchantId,
                hashKey,
                hashIV,
                isCreateOnTransaction,
                senderName,
                senderAddress,
                senderPhone,
                senderCellPhone,
                senderZipCode
            }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response: any) => {
                setShownSettingsModal(false);
                console.log(response);
                if (!response['data']) {
                    setErrorText("請稍候再試")
                    setShowErrorBanner(true);
                    setTimeout(() => {
                        setShowErrorBanner(false)
                    }, 3000)
                }
                if (response['data']['success'] === true) {
                    setSuccessText("成功更新設定")
                    setShowSuccessBanner(true);
                    setTimeout(() => {
                        setShowSuccessBanner(false)
                    }, 3000)
                } else {
                    setSuccessText("無法更新設定")
                    setShowErrorBanner(true);
                }
            });
    }

    if ((!dashboard || dashboard['success'] === false) && isLoaded) {
        return (
            <WixDesignSystemProvider features={{ newColorsBranding: true }}>
                <Page sidePadding={0}>
                    <Page.Content>
                        <Box padding="10px" align="center" verticalAlign="middle" height={"90vh"} direction="vertical">
                            <Cell>
                                <CircularProgressBar error value={100} />
                            </Cell>
                            <EmptyState
                                theme={'page-no-border'}
                                title="哎呀！？出事了 🤯"
                                subtitle="請刷新並重試。如果仍然無法正常工作，請通過右下角的 Messenger 聯繫支持人員。"
                            />
                        </Box>
                    </Page.Content>
                </Page>
            </WixDesignSystemProvider>)
    }

    if (!isLoaded) return (
        <WixDesignSystemProvider features={{ newColorsBranding: true }}>
            <Page height='100vh'>
                <Page.Content>
                    <Box height={"90vh"} direction="vertical" verticalAlign="middle">
                        <Loader text="加載中" />
                    </Box>
                </Page.Content>
            </Page></WixDesignSystemProvider>
    );

    if (!dashboard) {
        return null
    }

    if (!dashboard['configuration']) {
        setShownSettingsModal(true)
    }

    const siteId = dashboard['instance']['site']['siteId']
    const isUpgraded = !dashboard['instance']['instance']['isFree']
    const isUnlocked = true
    if (!isUnlocked) {
        setShowTrialEndedSection(true)
    }
    const totalProductCount = orders.length;
    const primaryAction = (orderId: string, isCompleted: boolean) => {
        return ({
            text: '查看',
            onClick: () => {
                setCurrentTransaction(orderId);
                isUnlocked ? setShownTransactionModal(true)
                    : setShownUpgradeModal(true)
            },
            disabled: !isCompleted
        });
    }

    const secondaryActions = (orderId: string) => {
        var options = [{
            text: '回報問題',
            icon: <Flag />,
            onClick: () => { window.Intercom('showNewMessage', `回報問題：Wix訂單ID：${orderId}`) },
            disabled: false
        }]
        if (orderId) {
            const shipment = (dashboard as any)['shipments'].find((v: { orderId: string; }) => v.orderId === orderId)
            if (shipment) {
                if (!!shipment['link'] && !(shipment?.ecpayResponse?.RtnCode === 1 || shipment?.ecpayResponse?.AllPayLogisticsID)) {
                    options.push({
                        text: '開啟物流選擇連結',
                        icon: <ExternalLink />,
                        onClick: () => { window.open(`${dashboard?.instance?.site?.url}/thank-you-page/${shipment.orderId}`) },
                        disabled: false
                    })
                    options.push({
                        text: '複製物流選擇連結',
                        icon: <Link />,
                        onClick: () => {
                            setCurrentUrl(`${dashboard?.instance?.site?.url}/thank-you-page/${shipment.orderId}`)
                            setShowCopyUrlModal(true)
                        },
                        disabled: false
                    })
                }
            } else {
                options.push({
                    text: '建立物流選擇連結',
                    icon: <Add />,
                    onClick: () => { 
                        if (isUpgraded) {
                            createshipmentlink(orderId)
                        } else {
                            setShownUpgradeModal(true)
                        }
                     },
                    disabled: !!shipment?.['ecpayRequest']
                })
            }
        }
        return options
    }
    const columns = [
        {
            title: 'Wix 訂單編號', render: (row: { number: number; orderId: string }) => (<Box gap="SP1" direction="horizontal" verticalAlign="middle"><Text size="small">{row.number}</Text><IconButton onClick={() => {
                window.open(`https://manage.wix.com/dashboard/${siteId}/store/orders/order/${row.orderId}`)
            }} size="tiny" skin="light" priority="primary"><ExternalLinkSmall /></IconButton></Box>)
        },
        { title: '運單編號', render: (row: { ecpayInvoiceNumber: string; }) => row.ecpayInvoiceNumber },
        { title: <Box direction="horizontal" gap="SP1"><Text size="small">運單狀態</Text><Tooltip content='在 Beta 期間，數據可能有延遲/沒有更新的。'><Badge size="tiny" skin="warningLight">Beta</Badge></Tooltip></Box>, render: (row: { ecpayStatusMsg: string; }) => row.ecpayStatusMsg },
        { title: '包裹狀態', render: (row: { status: any; }) => row.status },
        {
            title: "", render: (row: {
                ecpayStatusMsg: string;
                ecpayInvoiceNumber: string;
                orderId: string;
            }) => (<TableActionCell
                size="small"
                primaryAction={primaryAction(row.orderId, !!row.ecpayInvoiceNumber && !!row.ecpayStatusMsg)}
                secondaryActions={secondaryActions(row.orderId)}
                numOfVisibleSecondaryActions={1}
                popoverMenuProps={{ appendTo: 'window' }}
                moreActionsTooltipText="更多動作"
            />)
        }
    ];

    return (
        <WixDesignSystemProvider features={{ newColorsBranding: true }}>
                        <Layout>
                <Cell>
                    <Notification onClose={() => setShowSuccessBanner(false)} theme="success" show={showSuccessBanner}>
                        <Notification.TextLabel ellipsis={false}>
                            {successText}
                        </Notification.TextLabel>
                        <Notification.CloseButton />
                    </Notification>
                    <Notification onClose={() => setShowErrorBanner(false)} theme="error" show={showErrorBanner}>
                        <Notification.TextLabel ellipsis={false}>
                            {errorText}
                        </Notification.TextLabel>
                        <Notification.ActionButton onClick={() => window.Intercom('showNewMessage', `回報問題`)}>
                            聯繫支持
                        </Notification.ActionButton>
                        <Notification.CloseButton />
                    </Notification>
                </Cell>
            </Layout>
            <Notification theme="premium" show={!isUpgraded}>
                <Notification.TextLabel ellipsis={false}>
                    要使用物流整合，請升級至應用的 Premium 方案。
                </Notification.TextLabel>
                <Notification.ActionButton onClick={() => setShownUpgradeModal(true)}>
                    獲取 Premium
                </Notification.ActionButton>
            </Notification>
            <Page height='100vh'>
                <Page.Header
                    title="物流管理"
                    subtitle="建立及管理通過Wix網站訂單建立的綠界科技 ECPay 物流運單"
                    actionsBar={
                        <Box direction="horizontal" gap="SP1">
                            <Cell span={6}>
                                <Button size="medium" skin='standard' onClick={() => setShownSettingsModal(true)}>
                                    設定
                                </Button>
                            </Cell>
                            <Cell span={6}>
                                <Button disabled={isUpgraded} size="medium" skin='premium' prefixIcon={<PremiumFilled />} onClick={() => { setShownUpgradeModal(true) }}>
                                    升級應用
                                </Button>
                            </Cell>
                            <Cell span={2}>
                                <Tooltip content="幫助中心">
                                    <IconButton priority="secondary">
                                        <HelpCircle onClick={() => { window.open("https://support.certifiedcode.us/zh-TW/collections/3718054-%E8%AA%8D%E8%AD%89%E4%BB%A3%E7%A2%BC-shipments") }} />
                                    </IconButton>
                                </Tooltip>
                            </Cell>
                        </Box>}
                />
                <Page.Content>
                    {/* {isUpgraded ? <Box gap={"SP4"} direction="vertical">
                        <Card>
                            <SectionHelper
                                actionText="聯繫支持"
                                onAction={() => window.Intercom('showNewMessage', `設立自動跳轉`)}
                                title="設立自動跳轉，讓客人在結帳後自動前往物流頁面"

                            >
                                自動跳轉已在您的網站可用，要設立自動跳轉，請聯繫支持。
                            </SectionHelper>
                        </Card>
                        <Card></Card>
                    </Box> : <></>} */}
                    {showTrialEndedSection ? <Box gap={"SP4"} direction="vertical">
                        <Cell>
                            <SectionHelper
                                actionText="升級應用"
                                appearance="premium"
                                onAction={() => setShownUpgradeModal(true)}
                                title="想繼續建立運單？"
                            >
                                此應用試用期已結束，立即升級 Premium 方案，以建立運單。
                            </SectionHelper>
                        </Cell>
                        <Cell></Cell>
                    </Box> : <></>}
                    {orders.length === 0 ? <Box gap={"SP4"} direction="vertical"><Cell><EmptyState
                        image={<Image width="120px" src="https://www.wix-pages.com/wix-design-system-employees/EmptyState_Generic1.svg" transparent />}
                        title="建立一筆訂單以繼續建立運單"
                        subtitle="要建立運單，網站需要有一筆已完成的訂單。"
                        theme="page"
                    >
                        {/* <TextButton prefixIcon={<Add />} onClick={() => { wixNavigate(`orders`); }}>建立新訂單</TextButton> */}
                    </EmptyState></Cell><Cell></Cell></Box> : <Card stretchVertically={true} hideOverflow>
                        <TableToolbar>
                            <TableToolbar.Title>所有訂單</TableToolbar.Title>
                            <TableToolbar.ItemGroup position="start">
                                <TableToolbar.Item>
                                    <Text size="small">{totalProductCount} 筆訂單</Text>
                                </TableToolbar.Item>
                            </TableToolbar.ItemGroup>
                        </TableToolbar>
                        <Table
                            data={orders}
                            columns={columns}
                            rowVerticalPadding="medium"
                            loader={
                                <Box align="center" padding="24px 0px">
                                    <Loader size="small" />
                                </Box>
                            }
                        />
                    </Card>}
                    <Modal
                        isOpen={showCopyUrlModal}
                        onRequestClose={() => setShowCopyUrlModal(false)}
                        shouldCloseOnOverlayClick
                        screen="desktop"
                    >
                        <CustomModalLayout
                            title="複製此網址，以便在網站上建立運單"
                            removeContentPadding
                            onCloseButtonClick={() => setShowCopyUrlModal(false)}
                        >
                            <Page>
                                <Page.Content>
                                    <Box marginTop={5} display="block"><CopyClipboard value={currentUrl} resetTimeout={1500}>
                                        {({ isCopied, copyToClipboard }) => (
                                            <FormField>
                                                <Input
                                                    type="text"
                                                    readOnly
                                                    value={currentUrl}
                                                    suffix={
                                                        <Box verticalAlign="middle" marginRight="SP1">
                                                            <TextButton
                                                                onClick={() => copyToClipboard()}
                                                                size="small"
                                                                prefixIcon={<DuplicateSmall />}
                                                            >
                                                                {!isCopied ? '複製' : '已複製'}
                                                            </TextButton>
                                                        </Box>
                                                    }
                                                />
                                            </FormField>
                                        )}
                                    </CopyClipboard></Box></Page.Content></Page>
                        </CustomModalLayout>
                    </Modal>
                    <Modal
                        isOpen={shownSettingsModal}
                        onRequestClose={() => setShownSettingsModal(false)}
                        shouldCloseOnOverlayClick
                        screen="desktop"
                    >
                        <CustomModalLayout
                            primaryButtonText="儲存"
                            secondaryButtonText="取消"
                            onCloseButtonClick={() => setShownSettingsModal(false)}
                            secondaryButtonOnClick={() => setShownSettingsModal(false)}
                            primaryButtonOnClick={() => updateSettings()}
                            removeContentPadding
                            title="應用設定"
                            content={
                                <Page>
                                    <Page.Content>
                                        <Box marginTop={5} display="block">
                                            <Layout>
                                                <Cell span={12}>
                                                    <Card>
                                                        <Card.Header title='綠界商家帳號' subtitle='連線至綠界運單平台，以建立運單' />
                                                        <Card.Divider />
                                                        <Card.Content>
                                                            <Box direction='vertical' gap="SP2">
                                                                <FormField label="綠界商家代號" infoContent="格式為七位數數字">
                                                                    <Input clearButton value={merchantId} onChange={(e) => setMerchantId(e.target.value)} />
                                                                </FormField>
                                                                <FormField label="HashKey">
                                                                    <Input clearButton value={hashKey} onChange={(e) => setHashKey(e.target.value)} />
                                                                </FormField>
                                                                <FormField label="HashIV">
                                                                    <Input clearButton value={hashIV} onChange={(e) => setHashIV(e.target.value)} />
                                                                </FormField></Box>
                                                        </Card.Content>
                                                    </Card>
                                                </Cell>
                                                <Cell span={12}>

                                                    <Card>
                                                        <Card.Header title='寄件人資訊' subtitle='此部分必須填寫真實個人資料，否則會影響寄送。' />
                                                        <Card.Divider />
                                                        <Card.Content>
                                                            <Box direction='vertical' gap="SP2">
                                                                <FormField label="寄件人姓名" infoContent="字元限制為 10 個字元(最多 5 個中文字、10 個英文字)、不可有^ ‘ ` ! @# % & * + \ ” < >|_ [ ] ,，等符號且不允許空白，若帶有空白系統自動去除。
C2C店到店未取退回原寄件門市，須出示身分證件領取，請勿填寫公司名稱，避免無法領取退件。">
                                                                    <Input clearButton value={senderName} onChange={(e) => setSenderName(e.target.value)} />
                                                                </FormField>
                                                                <FormField label="退貨人郵遞區號">
                                                                    <Input clearButton value={senderZipCode} onChange={(e) => setSenderZipCode(e.target.value)} />
                                                                </FormField>
                                                                <FormField label="退貨人地址">
                                                                    <Input clearButton value={senderAddress} onChange={(e) => setSenderAddress(e.target.value)} />
                                                                </FormField>
                                                                <FormField label="退貨人手機">
                                                                    <Input clearButton value={senderCellPhone} onChange={(e) => setSenderCellPhone(e.target.value)} />
                                                                </FormField>
                                                                <FormField label="退貨人電話">
                                                                    <Input clearButton value={senderPhone} onChange={(e) => setSenderPhone(e.target.value)} />
                                                                </FormField>
                                                            </Box>
                                                        </Card.Content>
                                                    </Card>
                                                </Cell>
                                                <Cell span={12}>
                                                    <Card>
                                                        <Card.Header title='自動化' subtitle='通過自動化，您可以設定運單的發送時間，以及運單的建立方式。（更多功能即將到來）' />
                                                        <Card.Divider />
                                                        <Card.Content>
                                                            <Box direction="vertical" />
                                                            <Box verticalAlign="middle" align="space-between" padding={'0px 0px 18px'}>
                                                                <Box direction="vertical">
                                                                    <Text weight="normal">{"自動建立運單"}</Text>
                                                                    <Text secondary size="small">
                                                                        {'當訂單狀態已更新為「APPROVED（已核准）」時，自動建立運單，請確保綠界餘額充足以建立運單。'}
                                                                    </Text>
                                                                </Box>
                                                                <ToggleSwitch checked={isCreateOnTransaction} onChange={(e) => setIsCreateOnTransaction(e.target.checked)} />
                                                            </Box>
                                                        </Card.Content>
                                                    </Card>
                                                </Cell>
                                            </Layout>
                                        </Box>
                                    </Page.Content>
                                </Page>
                            }
                        />
                    </Modal>
                    <Modal
                        isOpen={shownUpgradeModal}
                        onRequestClose={() => setShownUpgradeModal(false)}
                        shouldCloseOnOverlayClick
                        screen="desktop"
                    ><AnnouncementModalLayout
                        theme="premium"
                        illustration={'https://www.wix-pages.com/wix-design-system-employees/generic_upgrade.svg'}
                        title="升級 Premium 方案，以建立自動化運單"
                        primaryButtonText="查看定價"
                        primaryButtonOnClick={() => { getUpgradeUrl(false) }}
                        linkOnClick={() => { getUpgradeUrl(true) }}
                        linkText="或了解永久授權，一次付款即可永久使用。"
                        onCloseButtonClick={() => { setShownUpgradeModal(false) }}
                    >
                            <Text>
                                使用「綠界科技 ECPay 物流整合」，為您的客戶提供更好的購物體驗，並節省您的時間。
                            </Text>
                        </AnnouncementModalLayout>
                    </Modal>
                    <Modal
                        isOpen={shownTransactionModal}
                        shouldCloseOnOverlayClick={true}
                    >
                        <CustomModalLayout
                            onCloseButtonClick={() => { setShownTransactionModal(false) }}
                            title={`訂單 #${(dashboard as any)['orders'].find((v: { id: string; }) => v.id === currentTransaction)?.number}`}
                            subtitle="此處顯示發送及接收綠界的原始數據"
                            footnote={
                                <Text size='small'>
                                    遇到問題？我們的支持團隊在線為您提供幫助。
                                </Text>
                            }
                            content={
                                <Box direction="vertical" gap={"SP2"}>
                                    <FormField labelSize="small" label="請求">
                                        <InputArea
                                            value={currentTransaction ? convertObjToList((dashboard as any)['shipments'].find((v: { orderId: string; }) => v.orderId === currentTransaction).ecpayRequest || {}, (requestMap as any)) : ""}
                                            autoGrow={true}
                                            minRowsAutoGrow={1}
                                            readOnly={true}
                                        />
                                    </FormField>
                                    <FormField labelSize="small" label="回應">
                                        <InputArea
                                            value={currentTransaction ? convertObjToList((dashboard as any)['shipments'].find((v: { orderId: string; }) => v.orderId === currentTransaction).ecpayResponse || {}, (responseMap as any)) : ""}
                                            autoGrow={true}
                                            minRowsAutoGrow={1}
                                            readOnly={true}
                                        />
                                    </FormField>
                                    <Box></Box>
                                    <Box></Box>
                                </Box>
                            }
                        />
                    </Modal>
                    <Layout>
                        <Cell>
                            <PageSection title="為您推薦" showDivider />
                        </Cell>
                        <Cell span={6}>
                            <Card>
                                <MarketingLayout
                                    title="接受線上付款"
                                    description="透過 Flows. 整合 OS，您可以使用本地支付服務商讓客戶使用台灣付款方式。"
                                    actions={<Button size="small" onClick={() => { window.open("https://www.flows.tw/payments/ecpay") }}>瞭解更多</Button>}
                                    size="tiny"
                                    badge={<Badge size="small">第三方整合</Badge>}
                                    image={
                                        <Box width="100%" align="right">
                                            <Image
                                                width="120px"
                                                src="https://www.wix-pages.com/wix-design-system-employees/generic_upgrade.svg"
                                                transparent
                                            />
                                        </Box>
                                    }
                                />
                            </Card>
                        </Cell>
                        <Cell span={6}>
                            <Card>
                                <MarketingLayout
                                    title="與支持團隊聯繫"
                                    description="當您遇到問題時，我們的支持團隊會幫助您解決並提供建議。"
                                    actions={<Button size="small" onClick={() => {
                                        window.Calendly.initPopupWidget({ url: 'https://calendly.com/certifiedcode/app-support-standard', text: 'Schedule time with me', color: '#0069ff', textColor: '#ffffff', branding: undefined });
                                    }}>預約通話</Button>}
                                    size="tiny"
                                    hiddenBadge
                                    alignItems="stretch"
                                    image={
                                        <Box width="100%" align="right">
                                            <Image
                                                width="120px"
                                                src="https://www.wix-pages.com/wix-design-system-employees/PromotionalBookingsUpgrade.svg"
                                                transparent
                                            />
                                        </Box>
                                    }
                                />
                            </Card>
                        </Cell>
                    </Layout>
                </Page.Content>
            </Page>
        </WixDesignSystemProvider>
    )
}

export default Home;
